.CodeMirror {
	line-height: 1.3;
	height: 400px;
  font-family: "Roboto mono", Menlo, Monaco, monospace;
  color: white;
  margin: 0 0 14px;
}

.CodeMirror span {
  color: white;
}

/* PADDING */

.CodeMirror-lines {
    padding: 4px 0; /* Vertical padding around content */
}
.CodeMirror pre {
    padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
    background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */

.CodeMirror-gutters {
    border-right: 1px solid #ddd;
    background-color: #f7f7f7;
    white-space: nowrap;
}
.CodeMirror-linenumbers {}
.CodeMirror-linenumber {
    padding: 0 3px 0 5px;
    min-width: 20px;
    text-align: right;
    color: #999;
    white-space: nowrap;
}

.CodeMirror-guttermarker { color: black; }
.CodeMirror-guttermarker-subtle { color: #999; }

/* CURSOR */

.CodeMirror div.CodeMirror-cursor {
    border-left: 1px solid black;
}
/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
    border-left: 1px solid silver;
}
.CodeMirror.cm-fat-cursor div.CodeMirror-cursor {
    width: auto;
    border: 0;
    background: #7e7;
}
.CodeMirror.cm-fat-cursor div.CodeMirror-cursors {
    z-index: 1;
}

.cm-animate-fat-cursor {
    width: auto;
    border: 0;
    -webkit-animation: blink 1.06s steps(1) infinite;
    -moz-animation: blink 1.06s steps(1) infinite;
    animation: blink 1.06s steps(1) infinite;
}
@-moz-keyframes blink {
    0% { background: #7e7; }
    50% { background: none; }
    100% { background: #7e7; }
}
@-webkit-keyframes blink {
    0% { background: #7e7; }
    50% { background: none; }
    100% { background: #7e7; }
}
@keyframes blink {
    0% { background: #7e7; }
    50% { background: none; }
    100% { background: #7e7; }
}

/* Can style cursor different in overwrite (non-insert) mode */
div.CodeMirror-overwrite div.CodeMirror-cursor {}

.cm-tab { display: inline-block; text-decoration: inherit; }

.CodeMirror-ruler {
    border-left: 1px solid #ccc;
    position: absolute;
}

/* DEFAULT THEME */

.cm-negative {color: #d44;}
.cm-positive {color: #292;}
.cm-header, .cm-strong {font-weight: bold;}
.cm-em {font-style: italic;}
.cm-link {text-decoration: underline;}
.cm-strikethrough {text-decoration: line-through;}

.cm-s-default .cm-error {color: #f00;}
.cm-invalidchar {color: #f00;}

.CodeMirror-composing { border-bottom: 2px solid; }

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {color: #0f0;}
div.CodeMirror span.CodeMirror-nonmatchingbracket {color: #f22;}
.CodeMirror-matchingtag { background: rgba(255, 150, 0, .3); }
.CodeMirror-activeline-background {background: #e8f2ff;}

/* STOP */

/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */

.CodeMirror {
    position: relative;
    overflow: hidden;
    background: white;
}

.CodeMirror-scroll {
    overflow: scroll !important; /* Things will break if this is overridden */
    /* 30px is the magic margin used to hide the element's real scrollbars */
    /* See overflow: hidden in .CodeMirror */
    margin-bottom: -30px; margin-right: -30px;
    padding-bottom: 30px;
    height: 100%;
    outline: none; /* Prevent dragging from highlighting the element */
    position: relative;
}
.CodeMirror .CodeMirror-sizer {
    position: relative;
    margin-left: 5px !important;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actuall scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
    position: absolute;
    z-index: 6;
    display: none;
}
.CodeMirror-vscrollbar {
    right: 0; top: 0;
    overflow-x: hidden;
    overflow-y: scroll;
}
.CodeMirror-hscrollbar {
    bottom: 0; left: 0;
    overflow-y: hidden;
    overflow-x: scroll;
}
.CodeMirror-scrollbar-filler {
    right: 0; bottom: 0;
}
.CodeMirror-gutter-filler {
    left: 0; bottom: 0;
}

.CodeMirror-gutters {
    position: absolute; left: 0; top: 0;
    z-index: 3;
}
.CodeMirror-gutter {
    white-space: normal;
    height: 100%;
    display: inline-block;
    margin-bottom: -30px;
    /* Hack to make IE7 behave */
    *zoom:1;
    *display:inline;
}
.CodeMirror-gutter-wrapper {
    position: absolute;
    z-index: 4;
    height: 100%;
}
.CodeMirror-gutter-elt {
    position: absolute;
    cursor: default;
    z-index: 4;
}
.CodeMirror-gutter-wrapper {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.CodeMirror-lines {
    cursor: text;
    min-height: 1px; /* prevents collapsing before first draw */
}
.CodeMirror pre {
    /* Reset some styles that the rest of the page might have set */
    -moz-border-radius: 0; -webkit-border-radius: 0; border-radius: 0;
    border-width: 0;
    background: transparent;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    white-space: pre;
    word-wrap: normal;
    line-height: inherit;
    color: inherit;
    z-index: 2;
    position: relative;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
}
.CodeMirror-wrap pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: normal;
}

.CodeMirror-linebackground {
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    z-index: 0;
}

.CodeMirror-linewidget {
    position: relative;
    z-index: 2;
    overflow: auto;
}

.CodeMirror-widget {}

.CodeMirror-code {
    outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.CodeMirror-measure {
    position: absolute;
    width: 100%;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}
.CodeMirror-measure pre { position: static; }

.CodeMirror div.CodeMirror-cursor {
    position: absolute;
    border-right: none;
    width: 0;
}

div.CodeMirror-cursors {
    visibility: hidden;
    position: relative;
    z-index: 3;
}
.CodeMirror-focused div.CodeMirror-cursors {
    visibility: visible;
}

.CodeMirror-selected { background: #d9d9d9; }
.CodeMirror-focused .CodeMirror-selected { background: #d7d4f0; }
.CodeMirror-crosshair { cursor: crosshair; }
.CodeMirror ::selection { background: #d7d4f0; }
.CodeMirror ::-moz-selection { background: #d7d4f0; }

.cm-searching {
    background: #ffa;
    background: rgba(255, 255, 0, .4);
}

/* IE7 hack to prevent it from returning funny offsetTops on the spans */
.CodeMirror span { *vertical-align: text-bottom; }

/* Used to force a border model for a node */
.cm-force-border { padding-right: .1px; }

@media print {
    /* Hide the cursor when printing */
    .CodeMirror div.CodeMirror-cursors {
        visibility: hidden;
    }
}

/* See issue #2901 */
.cm-tab-wrap-hack:after { content: ''; }

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext { background: none; }

.cm-s-default {
  background-color: #263238;
  color: rgba(233, 237, 237, 1);
  line-height: 1.6;
  border-radius: 3px
}
.cm-s-default .CodeMirror-gutters {
  background: #202A2F;
  color: rgb(83,127,126);
  border: none;
}

.cm-s-default .CodeMirror-linenumber {
  width: 36px !important;
  left: auto !important;
  background-color: #202A2F;
  padding-right: 10px;
}

.cm-s-default.CodeMirror {
  background-color: darken(#263238, 5%);
  transition: background-color 0.3s ease-in-out
}

.cm-s-default.CodeMirror-focused {
  background-color: #263238;
}
.cm-s-default .CodeMirror-guttermarker, .cm-s-default .CodeMirror-guttermarker-subtle, .cm-s-default .CodeMirror-linenumber { color: rgba(255,255,255,.5); }
.cm-s-default div.CodeMirror-cursor { border-left: 1px solid #f8f8f0; }
.cm-s-default div.CodeMirror-selected { background: rgba(255, 255, 255, 0.15); }
.cm-s-default.CodeMirror-focused div.CodeMirror-selected { background: rgba(255, 255, 255, 0.10); }
.cm-s-default .CodeMirror-line::selection, .cm-s-default .CodeMirror-line > span::selection, .cm-s-default .CodeMirror-line > span > span::selection { background: rgba(255, 255, 255, 0.10); }
.cm-s-default .CodeMirror-line::-moz-selection, .cm-s-default .CodeMirror-line > span::-moz-selection, .cm-s-default .CodeMirror-line > span > span::-moz-selection { background: rgba(255, 255, 255, 0.10); }

.cm-s-default .CodeMirror-hscrollbar::-webkit-scrollbar-track {
  background-color: #263238;
}

.cm-s-default .CodeMirror-scroll::-webkit-scrollbar-track {
  background-color: #263238;
}

.cm-s-default .CodeMirror-activeline-background { background: rgba(0, 0, 0, 0); }
.cm-s-default .cm-keyword { color: #ff9a56; }
.cm-s-default .cm-operator { color: rgba(233, 237, 237, 1); }
.cm-s-default .cm-variable-2 { color: #40C2EF; }
.cm-s-default .cm-variable-3 { color: rgba(233, 237, 237, 1); }
.cm-s-default .cm-builtin { color: #DECB6B; }
.cm-s-default .cm-atom { color: #40C2EF; }
.cm-s-default .cm-number { color: #40C2EF; }
.cm-s-default .cm-def { color: rgba(233, 237, 237, 1); }
.cm-s-default .cm-string { color: #00fcd4; }
.cm-s-default .cm-string-2 { color: #80CBC4; }
.cm-s-default .cm-comment { color: #546E7A; }
.cm-s-default .cm-variable { color: rgba(233, 237, 237, 1); }
.cm-s-default .cm-tag { color: #ff9a56; }
.cm-s-default .cm-meta { color: #80CBC4; }
.cm-s-default .cm-attribute { color: #FFA0E7; }
.cm-s-default .cm-property { color: #40C2EF; }
.cm-s-default .cm-qualifier { color: #FFA0E7; }
.cm-s-default .cm-error {
  color: rgba(255, 255, 255, 1.0);
  background-color: #EC5F67;
}
.cm-s-default .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}
